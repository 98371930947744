/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import createEmotionCache from "create-cache";
import {
  startTransition,
  StrictMode,
  useMemo,
  useState,
  useEffect,
} from "react";
import { hydrateRoot } from "react-dom/client";
import ClientStyleContext from "./ClientStyleContext";
import { CacheProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import {
  replayIntegration,
  browserTracingIntegration,
  init as sentryInit,
} from "@sentry/remix";

sentryInit({
  dsn: "https://5b370c3b3617dee8622895f5c4a70a2c@o1189964.ingest.us.sentry.io/4507073260421120",
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,

  integrations: [
    browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    replayIntegration(),
  ],
});

interface ClientCacheProviderProps {
  children: React.ReactNode;
}
function ClientCacheProvider({ children }: ClientCacheProviderProps) {
  const [cache, setCache] = useState(createEmotionCache());

  const clientStyleContextValue = useMemo(
    () => ({
      reset() {
        setCache(createEmotionCache());
      },
    }),
    []
  );

  return (
    <ClientStyleContext.Provider value={clientStyleContextValue}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>
  );
}

function clearBrowserExtensionInjectionsBeforeHydration() {
  document
    .querySelectorAll(
      [
        "html > *:not(body, head)",
        'script[src*="extension://"]',
        'link[href*="extension://"]',
      ].join(", ")
    )
    .forEach((s) => {
      s.parentNode?.removeChild(s);
    });

  const $targets = {
    html: {
      $elm: document.querySelector("html"),
      allowedAttributes: ["lang", "dir", "class"],
    },
    head: {
      $elm: document.querySelector("head"),
      allowedAttributes: [""],
    },
    body: {
      $elm: document.querySelector("body"),
      allowedAttributes: ["class"],
    },
  };

  Object.entries($targets).forEach(([, target]) => {
    target.$elm?.getAttributeNames().forEach((attr) => {
      if (!target.allowedAttributes.includes(attr)) {
        target.$elm?.removeAttribute(attr);
      }
    });
  });
}

startTransition(() => {
  clearBrowserExtensionInjectionsBeforeHydration();
  hydrateRoot(
    document,
    <StrictMode>
      <ClientCacheProvider>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <RemixBrowser />
      </ClientCacheProvider>
    </StrictMode>
  );
});
